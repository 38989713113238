<script setup lang="ts">
import { ref, computed } from 'vue'
import type { TagColor } from '/@src/components/base/tag/Tag.vue'

interface Feature {
  title: string
  text: string
  icon: string
  color: TagColor
  category: string
  plans: {
    basic: boolean
    casual: boolean
    pro: boolean
  }
}

type PlanType = 'all' | keyof Feature['plans']

const features: Feature[] = [
  {
    title: 'Easy Agent Creation',
    text: 'Create AI agents in minutes without any coding. Just answer a few questions.',
    icon: 'feather:pen-tool',
    color: 'primary',
    category: 'Core',
    plans: {
      basic: true,
      casual: true,
      pro: true
    }
  },
  {
    title: 'Payment Processing',
    text: 'Let your agents handle payments. Set up monetization for your AI services.',
    icon: 'feather:credit-card',
    color: 'warning',
    category: 'Monetization',
    plans: {
      basic: false,
      casual: false,
      pro: true
    }
  },
  {
    title: 'Branded pages',
    text: 'Create customizable Agency pages to monetize your AI services.',
    icon: 'feather:dollar-sign',
    color: 'warning',
    category: 'Monetization',
    plans: {
      basic: false,
      casual: false,
      pro: true
    }
  },
  {
    title: 'Conversation History',
    text: 'Access detailed chat histories and performance analytics for your agents.',
    icon: 'feather:bar-chart-2',
    color: 'info',
    category: 'Analytics',
    plans: {
      basic: false,
      casual: true,
      pro: true
    }
  },
  {
    title: 'Reports',
    text: 'After each conversation, bot creates an automatic report for you so you can get what you need.',
    icon: 'feather:file-text',
    color: 'info',
    category: 'Analytics',
    plans: {
      basic: false,
      casual: true,
      pro: true
    }
  },
  {
    title: 'Follow Up Questions',
    text: 'Send additional follow-up questions to chat participants to guide conversation the way you want.',
    icon: 'feather:message-circle',
    color: 'info',
    category: 'Communication',
    plans: {
      basic: false,
      casual: true,
      pro: true
    }
  },
  {
    title: 'Shared History',
    text: 'Create unique engagement scenarios by giving assistant access to shared chat history.',
    icon: 'feather:share-2',
    color: 'info',
    category: 'Communication',
    plans: {
      basic: false,
      casual: true,
      pro: true
    }
  },
  {
    title: 'Download Reports',
    text: 'Download comprehensive reports of your AI interactions and analytics.',
    icon: 'feather:download',
    color: 'info',
    category: 'Analytics',
    plans: {
      basic: false,
      casual: true,
      pro: true
    }
  },
  {
    title: 'Export to CSV',
    text: 'Export all conversation reports to a table to further work and share them.',
    icon: 'feather:file',
    color: 'info',
    category: 'Analytics',
    plans: {
      basic: false,
      casual: true,
      pro: true
    }
  },
  {
    title: 'Team Collaboration',
    text: 'Share agents with your team and manage access permissions efficiently.',
    icon: 'feather:users',
    color: 'primary',
    category: 'Team',
    plans: {
      basic: false,
      casual: false,
      pro: true
    }
  },
  {
    title: 'Custom Knowledge Base',
    text: 'Add your expertise and documents to create specialized AI agents.',
    icon: 'feather:database',
    color: 'warning',
    category: 'Core',
    plans: {
      basic: false,
      casual: true,
      pro: true
    }
  },
  {
    title: 'MiniApp',
    text: 'Let anyone chat with your bot in telegram.',
    icon: 'feather:smartphone',
    color: 'warning',
    category: 'Integration',
    plans: {
      basic: true,
      casual: true,
      pro: true
    }
  },
  {
    title: 'Web Chat',
    text: 'Each bot comes with a personal and sharable web page.',
    icon: 'feather:globe',
    color: 'warning',
    category: 'Integration',
    plans: {
      basic: true,
      casual: true,
      pro: true
    }
  },
  {
    title: 'Embedable Widget',
    text: 'Place an agent on your website with a few lines of code.',
    icon: 'feather:code',
    color: 'warning',
    category: 'Integration',
    plans: {
      basic: true,
      casual: true,
      pro: true
    }
  },
  {
    title: 'Telegram Integration',
    text: 'Turn your AI agents into powerful Telegram bots instantly.',
    icon: 'feather:send',
    color: 'info',
    category: 'Integration',
    plans: {
      basic: false,
      casual: true,
      pro: true
    }
  }
]

const expandedFeature = ref<string | null>(null)
const selectedPlan = ref<PlanType>('pro')

const toggleFeature = (title: string) => {
  expandedFeature.value = expandedFeature.value === title ? null : title
}

interface PlanFilter {
  name: string
  value: PlanType
  color: TagColor
}

const planFilters: PlanFilter[] = [
  { name: 'Basic', value: 'basic', color: 'info' },
  { name: 'Casual', value: 'casual', color: 'warning' },
  { name: 'Pro', value: 'pro', color: 'secondary' }
]

const filteredFeatures = computed(() => {
  if (selectedPlan.value === 'all') return features
  const plan = selectedPlan.value
  return features.filter(feature => feature.plans[plan])
})

const setSelectedPlan = (plan: PlanType) => {
  selectedPlan.value = plan
}
</script>

<template>
  <div class="feature-grid">
    <Title
      :size="6"
      class="has-text-centered"
    >
      Feature explorer:
    </Title>
    <div class="filters">
      <Tag
        v-for="filter in planFilters"
        :key="filter.value"
        :color="selectedPlan === filter.value ? filter.color : 'primary'"
        :label="filter.name"
        :outlined="selectedPlan !== filter.value"
        rounded
        clickable
        @click="setSelectedPlan(filter.value)"
      />
    </div>
    <div class="feature-grid-container">
      <button
        v-for="feature in filteredFeatures"
        :key="feature.title"
        class="feature-card"
        :class="{ 'is-expanded': expandedFeature === feature.title }"
        @click="toggleFeature(feature.title)"
        @keydown.enter="toggleFeature(feature.title)"
      >
        <div class="feature-header">
          <div class="feature-icon">
            <i
              class="iconify"
              :class="`has-text-${feature.color}`"
              :data-icon="feature.icon"
            />
          </div>
          <div class="feature-title">
            <h4>{{ feature.title }}</h4>
          </div>
        </div>
        <div class="feature-content">
          <p>{{ feature.text }}</p>
        </div>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.feature-grid {
  width: 100%;
  padding: 1rem;

  .filters {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    cursor: pointer;
  }

  .feature-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1rem;
    width: 100%;
  }

  .feature-card {
    background: var(--card-bg-color);
    border: 1px solid var(--card-border-color);
    border-radius: 0.75rem;
    padding: 1.25rem;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    text-align: left;

    &:hover {
      transform: translateY(-2px);
      box-shadow: var(--light-box-shadow);
    }

    .feature-header {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .feature-icon {
        .iconify {
          font-size: 1.5rem;
        }
      }

      .feature-title {
        flex-grow: 1;
        h4 {
          font-size: 1rem;
          font-weight: 600;
          margin: 0;
          color: var(--title-color);
        }
      }
    }

    .feature-content {
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition: all 0.3s ease;

      p {
        margin: 0;
        padding-top: 0.75rem;
        color: var(--light-text);
        font-size: 0.9rem;
        line-height: 1.5;
      }
    }

    &.is-expanded {
      .feature-content {
        height: auto;
        opacity: 1;
      }
    }
  }
}

// Mobile optimizations
@media screen and (max-width: 768px) {
  .feature-grid {
    padding: 0.5rem;

    .filters {
      padding: 0 0.5rem;
      margin-bottom: 1rem;
    }

    .feature-grid-container {
      grid-template-columns: 1fr;
    }

    .feature-card {
      padding: 1rem;
    }
  }
}
</style>
