<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

defineProps({
  titleText: {
    type: String,
    default: 'Build AI agents that work and earn for you',
  },
  poweredByImageSrc: {
    type: String,
    default: '/assets/illustrations/gpt_logo.svg',
  },
  poweredByText: {
    type: String,
    default: 'Powered by GPT-4o',
  },
  subtitleText: {
    type: String,
    default: 'Start in seconds, no coding required',
  },
  primaryButtonText: {
    type: String,
    default: 'Start for free',
  },
  primaryButtonLink: {
    type: String,
    default: '/app/create',
  },
  secondaryButtonText: {
    type: String,
    default: 'Learn more',
  },
  secondaryButtonLink: {
    type: String,
    default: '/',
  },
})

const windowWidth = ref(import.meta.env.SSR ? 0 : window.innerWidth)
const isLoading = ref(true)
const messageCount = ref(0)

function updateWindowWidth() {
  windowWidth.value = window.innerWidth
}

function simulateLoading() {
  isLoading.value = true
  setTimeout(() => {
    isLoading.value = false
    messageCount.value = 0
  }, 1500)
}

onMounted(() => {
  window.addEventListener('resize', updateWindowWidth)
  simulateLoading()
})

onUnmounted(() => {
  window.removeEventListener('resize', updateWindowWidth)
})
</script>

<template>
  <Hero
    alignment="center"
    size="fullheight"
  >
    <template #body>
      <!--Hero Content-->
      <div class="mx-auto max-w-6 mb-6 mt-20">
        <div class="powered-by">
          <img
            :src="poweredByImageSrc"
            alt="Powered by"
            width="12"
            height="12"
          >
          <p class="paragraph">
            {{ poweredByText }}
          </p>
        </div>

        <div class="title-container">
          <Title
            tag="h1"
            :size="1"
            class="font-alt"
          >
            {{ titleText }}
          </Title>
        </div>

        <Subtitle
          tag="p"
          :size="5"
          weight="thin"
          class="mx-auto max-w-6 b-centered-tablet-p pt-2"
        >
          {{ subtitleText }}
        </Subtitle>
        <!--Buttons-->
        <div class="buttons is-relative z-1">
          <Button
            color="primary"
            :long="3"
            bold
            raised
            :to="primaryButtonLink"
          >
            {{ primaryButtonText }}
          </Button>
          <Button
            :long="3"
            bold
            elevated
            outlined
            :href="secondaryButtonLink"
          >
            {{ secondaryButtonText }}
          </Button>
        </div>
      </div>

      <!--Hero App Mockup-->
      <SolutionsPreviews class="w-3" />
    </template>
  </Hero>
</template>

<style lang="scss" scoped>
:deep(.hero) {
  overflow: hidden !important;
}

:deep(.hero-body) {
  padding-top: 0 !important;
  display: block !important;
}

.font-alt {
  font-family: var(--font-extra);
}

.powered-by {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: var(--light-text);
  padding-bottom: 0.5rem;

  img {
    margin-right: 0.5rem;
  }
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.message-count {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--danger);
  color: var(--white);
  font-family: var(--font-alt);
  border-radius: 20px;
  margin: 0 8px;
  width: 70px;
  height: 50px;
  font-size: 2.5rem;
  font-weight: bold;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loader {
  width: 30px;
  height: 30px;
  border: 3px solid var(--white);
  border-top-color: transparent;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (width <= 767px) {
  .font-alt {
    font-size: 1.5rem;
  }
}

.buttons {
  display: flex;
  gap: 1rem;
}

.is-relative {
  position: relative;
}

.z-1 {
  z-index: 1;
}

.paragraph {
  font-size: 0.9rem;
  color: var(--light-text);
}

.b-centered-tablet-p {
  text-align: center;
}

.pt-2 {
  padding-top: 0.5rem;
}
</style>
