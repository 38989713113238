<script setup lang="ts">
export interface CompanyGridProps {
  limit?: number
  inverted?: boolean
  compact?: boolean
}

const props = withDefaults(defineProps<CompanyGridProps>(), {
  title: undefined,
  subtitle: undefined,
  limit: 15,
  inverted: false,
  compact: false,
})

const clientLogos = [
  'bitsgap.svg',
  'cointraffic.svg',
  'findanode.svg',
  'innovators.svg',
  'metaxilazis.svg',
  'perfect.svg',
  'rocketfox.svg',
  'serverpartners.svg',
  'theintelligent.svg',
  'wois.svg'
].map(filename => `/assets/brands/clients/${filename}`)
</script>

<template>
  <div class="py-6">
    <div class="company-grid">
      <div
        class="company-logos"
        :class="[props.inverted && 'is-inverted', 'has-text-paragraph']"
      >
        <div class="logo-container">
          <div
            v-for="(logo, index) in clientLogos.slice(0, props.limit)"
            :key="index"
            class="logo-item"
          >
            <div class="logo-wrap">
              <img
                class="is-relative"
                :src="logo"
                :alt="`Client logo ${index + 1}`"
                width="300"
                height="107"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.company-grid {
  position: relative;

  .company-logos {
    max-width: 940px;
    margin: 0 auto;
    color: var(--paragraph-color);

    .logo-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.5rem;
    }

    .logo-item {
      flex: 0 0 auto;
      width: calc(20% - 1.5rem); /* 5 items per row by default */
    }

    .logo-wrap {
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      display: block;
      max-width: 90px;
      min-width: 90px;
      max-height: 50px;
      margin: 0 auto;
      filter: none;

      :deep(path),
      :deep(rect),
      :deep(circle),
      :deep(polygon) {
        fill: currentColor;
      }
    }

    &.is-inverted {
      color: white;
    }
  }
}

.is-dark {
  .company-grid {
    .company-logos {
      color: white;
    }
  }
}

/* Responsive adjustments */
@media only screen and (max-width: 1023px) {
  .company-grid .company-logos .logo-item {
    width: calc(25% - 1.5rem); /* 4 items per row */
  }
}

@media only screen and (max-width: 767px) {
  .company-grid {
    .company-logos {
      .logo-item {
        width: calc(33.333% - 1.5rem); /* 3 items per row */
      }

      img {
        display: block;
        max-width: 80px;
        min-width: 80px;
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .company-grid .company-logos .logo-item {
    width: calc(50% - 1.5rem); /* 2 items per row on very small screens */
  }
}
</style>
