<script setup lang="ts">
import { ref, onMounted, onActivated, watch } from 'vue'
import { useManageSubscription } from '/@src/composables/useManageSubscription'
import useAuth from '/@src/composables/useAuth'

interface SubscriptionInfo {
  is_active: boolean
  current_plan: string
  available_upgrades: string[]
}

interface Feature {
  text: string
  included: boolean
}

interface PricingPlan {
  name: string
  description: string
  monthlyPrice: number
  messagePrice: number
  features: Feature[]
  actionLabel: string
  popular: boolean
  plan: string
}

const plans: PricingPlan[] = [
  {
    name: 'Basic',
    description: 'Perfect to start creating',
    monthlyPrice: 0,
    messagePrice: 9,
    features: [
      { text: '100 free messages per month', included: true },
      { text: 'Embedable widget', included: true },
      { text: 'Connect to Telegram bot', included: false },
      { text: 'Knowledge base', included: false },
      { text: 'Conversation history & reports', included: false },
      { text: 'Custom branding', included: false },
      { text: 'Payment processing', included: false },
    ],
    actionLabel: 'Get Started',
    popular: false,
    plan: 'basic'
  },
  {
    name: 'Casual',
    description: 'Let AI handle routine chats',
    monthlyPrice: 79,
    messagePrice: 4,
    features: [
      { text: '1,000 free messages per month', included: true },
      { text: 'Embedable widget', included: true },
      { text: 'Connect to Telegram bot', included: true },
      { text: 'Knowledge base', included: true },
      { text: 'Conversation history & reports', included: true },
      { text: 'Custom branding', included: false },
      { text: 'Payment processing', included: false },
    ],
    actionLabel: 'Start Casual',
    popular: true,
    plan: 'casual'
  },
  {
    name: 'Pro',
    description: 'Covering AI powered business',
    monthlyPrice: 490,
    messagePrice: 2,
    features: [
      { text: '10,000 free messages per month', included: true },
      { text: 'Embedable widget', included: true },
      { text: 'Connect to Telegram bot', included: true },
      { text: 'Knowledge base', included: true },
      { text: 'Conversation history & reports', included: true },
      { text: 'Custom branding', included: true },
      { text: 'Payment processing', included: true },
    ],
    actionLabel: 'Go Pro',
    popular: false,
    plan: 'pro'
  }
]


const { isAuthenticated, login, checkSubscription } = useAuth()
const { subscriptionLink, manageSubscription } = useManageSubscription()

const error = ref<string | null>(null)
const subscriptionInfo = ref<SubscriptionInfo | null>(null)
const loadingPlan = ref<string | null>(null)
const isCheckingSubscription = ref(false)

const isLoading = computed(() => isCheckingSubscription.value)
const isPlanLoading = computed(() => (plan: string) => loadingPlan.value === plan)
const isAnyPlanLoading = computed(() => loadingPlan.value !== null)

const checkCurrentSubscription = async (force = false) => {
  if (!isAuthenticated.value && !force) return

  isCheckingSubscription.value = true
  console.log('Checking subscription...')
  try {
    const result = await checkSubscription()
    console.log('Subscription check result:', result)
    if (typeof result === 'object' && result !== null) {
      const data = result as { current_plan: string; available_upgrades: string[]; is_active: boolean }
      // Force reactivity update by creating a new object
      subscriptionInfo.value = { ...data }
      console.log('Subscription info updated:', subscriptionInfo.value)
    }
  } catch (err) {
    console.error('Failed to check subscription:', err)
    error.value = 'Failed to load subscription information'
  } finally {
    loadingPlan.value = null
    isCheckingSubscription.value = false
  }
}

// Debug watcher for subscription info
watch(subscriptionInfo, (newValue) => {
  console.log('Subscription info changed:', newValue)
}, { deep: true })

onMounted(() => {
  // Force check on mount regardless of auth state
  checkCurrentSubscription(true)
})

// Watch for auth state changes
watch(isAuthenticated, (newValue) => {
  if (newValue) {
    checkCurrentSubscription(true)
  } else {
    subscriptionInfo.value = null
  }
})

// Re-check subscription when component becomes visible
onActivated(() => {
  checkCurrentSubscription(true)
})

const getButtonState = (plan: PricingPlan) => {
  if (!subscriptionInfo.value) {
    return {
      text: plan.actionLabel,
      disabled: false,
      isCurrentPlan: false,
      canUpgrade: true
    }
  }

  const isCurrentPlan = subscriptionInfo.value.current_plan === plan.plan
  const canUpgrade = subscriptionInfo.value.available_upgrades.includes(plan.plan)

  return {
    text: isCurrentPlan ? 'Manage Plan' : (subscriptionInfo.value?.is_active ? 'Upgrade' : 'Start free trial'),
    disabled: !isCurrentPlan && !canUpgrade,
    isCurrentPlan,
    canUpgrade: !isCurrentPlan && canUpgrade
  }
}

const handleSubscription = async (plan: string) => {
  console.log('Handle subscription called:', { plan, currentInfo: subscriptionInfo.value })
  if (import.meta.env.SSR) return

  loadingPlan.value = plan
  error.value = null

  try {
    if (isAuthenticated.value) {
      const isCurrentPlan = subscriptionInfo.value?.current_plan === plan
      let action: 'manage' | 'upgrade' | 'subscribe'

      if (isCurrentPlan) {
        action = 'manage'
      } else if (subscriptionInfo.value?.is_active) {
        action = 'upgrade'
      } else {
        action = 'subscribe'
      }

      console.log('Handling subscription:', { plan, action, isCurrentPlan })
      await manageSubscription(plan, action)

      // Handle different response formats based on action
      if (action === 'manage' && subscriptionLink.value) {
        window.location.href = subscriptionLink.value
      } else if ((action === 'upgrade' || action === 'subscribe') && subscriptionLink.value) {
        window.location.href = subscriptionLink.value
      } else {
        throw new Error('No valid subscription URL received')
      }
    } else {
      await login()
    }
  } catch (err: any) {
    console.error('Failed to process subscription:', err)
    if (err.response?.data?.detail) {
      error.value = err.response.data.detail
    } else {
      error.value = err.message || 'An error occurred while processing your request.'
    }
  } finally {
    loadingPlan.value = null
  }
}
</script>

<template>
  <div class="pricing-plans">
    <div class="pricing-plans-box">
      <div class="columns is-gapless b-columns-half-tablet-p">
        <div
          v-for="(plan, index) in plans"
          :key="index"
          class="column is-4"
        >
          <div class="pricing-inner-box">
            <div
              v-if="plan.popular"
              class="popular-tag"
            >
              <Tag
                color="secondary"
                label="Most Popular"
                elevated
              />
            </div>
            <Title
              tag="h3"
              :size="5"
              weight="semi"
            >
              <span>{{ plan.name }}</span>
            </Title>
            <p class="paragraph rem-90">
              {{ plan.description }}
            </p>
            <div class="price">
              <span>{{ plan.monthlyPrice }}</span>
            </div>
            <div class="billing">
              <p class="paragraph rem-80">
                per month
              </p>
              <div class="price-message">
                <span>{{ plan.messagePrice }}¢</span>
              </div>
              <p class="paragraph rem-80">
                per message
              </p>
            </div>
            <div class="features">
              <ul>
                <li
                  v-for="(feature, featureIndex) in plan.features"
                  :key="featureIndex"
                  :class="{ 'is-disabled': !feature.included }"
                >
                  <i
                    class="iconify"
                    :class="feature.included ? 'has-text-primary' : 'has-text-light'"
                    :data-icon="feature.included ? 'feather:check' : 'feather:x'"
                  />
                  <span>{{ feature.text }}</span>
                </li>
              </ul>
            </div>
            <div class="action">
              <Button
                color="primary"
                :outlined="!plan.popular || getButtonState(plan).isCurrentPlan"
                bold
                fullwidth
                raised
                :loading="isLoading || isPlanLoading(plan.plan)"
                :disabled="isLoading || isAnyPlanLoading || getButtonState(plan).disabled"
                @click="handleSubscription(plan.plan)"
              >
                <span>{{ getButtonState(plan).text }}</span>
              </Button>
            </div>
            <div
              v-if="getButtonState(plan).isCurrentPlan"
              class="current-plan-tag"
            >
              <Tag
                color="primary"
                label="Current Plan"
                elevated
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <p
      v-if="error"
      class="error-message has-text-danger has-text-centered mt-4"
    >
      {{ error }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.current-plan-tag {
  position: absolute;
  top: -0.75rem;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.pricing-plans {
  position: relative;
  margin-bottom: 3rem;

  .pricing-plans-box {
    position: relative;
    background: var(--card-bg-color);
    border: 1px solid var(--card-border-color);
    border-radius: 0.85rem;
    box-shadow: var(--spread-shadow);

    .column {
      &:not(:last-child) {
        border-right: 1px solid var(--card-border-color);

        @media screen and (max-width: 768px) {
          border-right: none;
          border-bottom: 1px solid var(--card-border-color);
        }
      }
    }

    .pricing-inner-box {
      position: relative;
      padding: 2rem;
      text-align: center;

      .popular-tag {
        position: absolute;
        top: -0.75rem;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      .price {
        padding-top: 1rem;

        span {
          position: relative;
          left: -10px;
          font-family: var(--font);
          font-size: 3.25rem;
          font-weight: 600;
          color: var(--title-color);

          &::before {
            position: relative;
            top: -25px;
            content: '$';
            font-weight: 700;
            font-size: 1.25rem;
            margin-right: 0;
          }
        }
      }

      .price-message {
        padding: 1rem 0 0.25rem;

        span {
          position: relative;

          font-family: var(--font);
          font-size: 1.25rem;
          font-weight: 400;
          color: var(--title-color);

          &::before {
            position: relative;
            top: -2px;
            content: '+';
            font-weight: 700;
            font-size: 0.9rem;
            margin-right: 0;
          }
        }
      }

      .billing {
        margin-bottom: 1rem;
      }

      .features {
        margin: 2rem 0;
        text-align: left;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            display: flex;
            align-items: center;
            padding: 0.5rem 0;
            color: var(--light-text);

            &.is-disabled {
              opacity: 0.5;
            }

            .iconify {
              margin-right: 0.5rem;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}
</style>
