<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

const words = ['EARN', 'CHAT', 'PLAY', 'ASK']
const currentWord = ref(words[0])
const displayWord = ref(words[0])
const isChanging = ref(false)

const specialChars = ['@', '#', '$', '%', '&', '¥', '¢', '£', '∆', 'Ω', '∑', 'π', '√', '∞', '≈', '†', '‡', '©', '®', '™']
let wordInterval
let scrambleInterval

function getRandomChar() {
  return specialChars[Math.floor(Math.random() * specialChars.length)]
}

function scrambleText(targetWord) {
  // Clear any existing interval first
  if (scrambleInterval) {
    clearInterval(scrambleInterval)
  }

  let scrambleCount = 0
  const maxScrambles = 12 // Increased for more scramble steps
  const scrambleDuration = 40 // Faster iterations
  let timeoutId = null

  const performScramble = () => {
    if (scrambleCount >= maxScrambles) {
      displayWord.value = targetWord
      clearInterval(scrambleInterval)
      scrambleInterval = null
      // Clear any pending timeout
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      return
    }

    let scrambledText = ''
    for (let i = 0; i < targetWord.length; i++) {
      // Gradually reveal correct letters
      if (Math.random() < scrambleCount / maxScrambles) {
        scrambledText += targetWord[i]
      } else {
        scrambledText += getRandomChar()
      }
    }
    displayWord.value = scrambledText
    scrambleCount++
  }

  scrambleInterval = setInterval(performScramble, scrambleDuration)

  // Safety timeout to ensure the scramble effect completes
  timeoutId = setTimeout(() => {
    if (scrambleInterval) {
      clearInterval(scrambleInterval)
      scrambleInterval = null
      displayWord.value = targetWord
    }
  }, maxScrambles * scrambleDuration * 1.5) // Give extra time as safety margin
}

defineProps({
  poweredByImageSrc: {
    type: String,
    default: '/assets/illustrations/gpt_logo.svg',
  },
  titleText: {
    type: String,
    default: 'Powered by GPT-4o',
  },
  poweredByText: {
    type: String,
    default: 'Powered by GPT-4o',
  },
  subtitleText: {
    type: String,
    default: 'Create AI agents instantly for all your customer research needs.',
  },
  primaryButtonText: {
    type: String,
    default: 'Create Agent for Free',
  },
  primaryButtonLink: {
    type: String,
    default: '/app/create',
  },
})

const windowWidth = ref(import.meta.env.SSR ? 0 : window.innerWidth)
const isLoading = ref(true)
const messageCount = ref(0)

function updateWindowWidth() {
  windowWidth.value = window.innerWidth
}

function simulateLoading() {
  isLoading.value = true
  setTimeout(() => {
    isLoading.value = false
    messageCount.value = 0
  }, 1500)
}

function rotateWord() {
  try {
    isChanging.value = true
    const currentIndex = words.indexOf(currentWord.value)
    const nextIndex = (currentIndex + 1) % words.length
    const nextWord = words[nextIndex]

    // Start scramble effect
    scrambleText(nextWord)

    // Update current word after scramble finishes
    setTimeout(() => {
      currentWord.value = nextWord
      isChanging.value = false
    }, 600) // Increased to match new scramble duration
  } catch (error) {
    console.error('Error in word rotation:', error)
    // Ensure we reset the state in case of error
    isChanging.value = false
    if (scrambleInterval) {
      clearInterval(scrambleInterval)
      scrambleInterval = null
    }
  }
}

onMounted(() => {
  window.addEventListener('resize', updateWindowWidth)
  simulateLoading()
  wordInterval = setInterval(rotateWord, 3000) // Increased interval for better effect visibility
})

onUnmounted(() => {
  window.removeEventListener('resize', updateWindowWidth)
  if (wordInterval) {
    clearInterval(wordInterval)
    wordInterval = null
  }
  if (scrambleInterval) {
    clearInterval(scrambleInterval)
    scrambleInterval = null
  }
})
</script>

<template>
  <Hero
    alignment="center"
    size="fullheight"
    :class="{ 'glitching': isChanging }"
  >
    <template #body>
      <!--Hero Content-->
      <div class="mx-auto max-w-6 mb-6 mt-20">
        <div class="powered-by">
          <img
            :src="poweredByImageSrc"
            alt="Powered by"
            width="12"
            height="12"
          >
          <p class="paragraph">
            {{ poweredByText }}
          </p>
        </div>

        <div class="title-container">
          <Title
            tag="h1"
            :size="1"
            class="font-alt"
          >
            <div class="text-primary">
              CHAT BOTS THAT
            </div>
            <div
              class="dynamic-word"
              :class="{ 'fade-out': isChanging }"
              :data-text="displayWord"
            >
              {{ displayWord }}
            </div>
            <div class="text-primary">
              FOR YOU
            </div>
          </Title>
        </div>

        <Subtitle
          tag="p"
          :size="5"
          weight="thin"
          class="mx-auto max-w-5 b-centered-tablet-p pt-2"
        >
          Get started in seconds – for free.
        </Subtitle>
        <!--Buttons-->
        <div class="buttons is-relative z-1">
          <Button
            color="primary"
            :long="3"
            size="large"
            bold
            raised
            :to="primaryButtonLink"
          >
            {{ primaryButtonText }}
          </Button>
        </div>
      </div>
      <Title
        narrow
        :size="6"
      >
        Clients:
      </Title>
      <CompanyGrid
        :limit="20"
      />
      <FeaturedAssistants featured />
    </template>
  </Hero>
</template>

<style lang="scss" scoped>
:deep(.hero) {
  overflow: hidden !important;
  position: relative;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
    mix-blend-mode: color-dodge;
    backdrop-filter: blur(1px);
  }

  &.glitching::before {
    opacity: 1;
    animation: background-glitch 0.3s cubic-bezier(.25, .46, .45, .94) infinite;
    background: linear-gradient(45deg,
      rgba(var(--secondary-rgb), 0.2) 0%,
      transparent 35%,
      rgba(var(--secondary-rgb), 0.2) 65%,
      transparent 100%
    );
  }
}

:deep(.hero-body) {
  padding-top: 0 !important;
  display: block !important;
  position: relative;
  z-index: 1;
}

@keyframes background-glitch {
  0%, 100% {
    transform: scale(1) translate(0);
    filter: none;
  }
  20% {
    transform: scale(1.02) translate(-10px, 10px) skew(2deg);
    filter: brightness(1.2) contrast(1.3) hue-rotate(90deg);
  }
  40% {
    transform: scale(0.98) translate(15px, -5px) skew(-2deg);
    filter: brightness(0.8) contrast(1.5) hue-rotate(-90deg);
  }
  60% {
    transform: scale(1.01) translate(-5px, -10px) skew(1deg);
    filter: brightness(1.3) contrast(1.2) hue-rotate(45deg);
  }
  80% {
    transform: scale(0.99) translate(10px, 5px) skew(-1deg);
    filter: brightness(0.7) contrast(1.6) hue-rotate(-45deg);
  }
}

.font-alt {
  font-family: var(--font-extra);
  font-weight: bold;
}

.powered-by {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: var(--light-text);
  padding-bottom: 0.5rem;

  img {
    margin-right: 0.5rem;
  }
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.message-count {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--danger);
  color: var(--white);
  font-family: var(--font-alt);
  border-radius: 20px;
  margin: 0 8px;
  width: 70px;
  height: 50px;
  font-size: 2.5rem;
  font-weight: bold;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loader {
  width: 30px;
  height: 30px;
  border: 3px solid var(--white);
  border-top-color: transparent;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spin 0.01s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (width <= 767px) {
  .font-alt {
    font-size: 2rem;
  }
}

.buttons {
  display: flex;
  gap: 1rem;
}

.is-relative {
  position: relative;
}

.z-1 {
  z-index: 1;
}

.paragraph {
  font-size: 0.9rem;
  color: var(--light-text);
}

.dynamic-word {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  font-size: 6rem;
  position: relative;
  color: var(--secondary);
  transform-style: preserve-3d;
  perspective: 500px;
  letter-spacing: 0.05em;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
  min-height: 1.2em;
  line-height: 1.2;
}

@media (width <= 767px) {
  .dynamic-word {
    min-height: 1.2em;
    font-size: 3rem;
  }
}

.fade-out {
  animation: glitch 0.3s cubic-bezier(.25, .46, .45, .94) infinite;
  position: relative;
}

@keyframes glitch {
  0%, 100% {
    transform: translate(0) scale(1) skew(0);
    filter: none;
  }
  20% {
    transform: translate(-3px, 3px) scale(1.03) skew(2deg);
    filter: brightness(1.3) contrast(1.5) hue-rotate(90deg);
  }
  40% {
    transform: translate(3px, -3px) scale(0.97) skew(-2deg);
    filter: brightness(0.7) contrast(2) hue-rotate(-90deg);
  }
  60% {
    transform: translate(-2px, 2px) scale(1.05) skew(1deg);
    filter: brightness(1.5) contrast(1.2) hue-rotate(45deg);
  }
  80% {
    transform: translate(2px, -2px) scale(0.95) skew(-1deg);
    filter: brightness(0.9) contrast(1.7) hue-rotate(-45deg);
  }
}

.fade-out::before,
.fade-out::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  mix-blend-mode: screen;
}

.fade-out::before {
  animation: glitch-layer 0.3s ease infinite;
  text-shadow: 2px 0 #ff0000;
  clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
}

.fade-out::after {
  animation: glitch-layer 0.3s ease-out infinite reverse;
  text-shadow: -2px 0 #00ff00;
  clip-path: polygon(0 60%, 100% 60%, 100% 100%, 0 100%);
}

@keyframes glitch-layer {
  0% {
    transform: translate(-5px, 5px);
  }
  33% {
    transform: translate(4px, -4px);
  }
  66% {
    transform: translate(-3px, 3px);
  }
  100% {
    transform: translate(5px, -5px);
  }
}

.dynamic-word::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    transparent 0%,
    rgba(var(--secondary-rgb), 0.2) 50%,
    transparent 100%
  );
  animation: scan 2s linear infinite;
  pointer-events: none;
}

@keyframes scan {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

.b-centered-tablet-p {
  text-align: center;
}

.pt-2 {
  padding-top: 0.5rem;
}
</style>
