// useManageSubscription.ts
import { ref, getCurrentInstance } from 'vue'
import axios from 'axios'
import useAuth from '/@src/composables/useAuth'
import { useUserStore } from '/@src/stores/userStore'
import { useToast } from 'vue-toastification'

export function useManageSubscription() {
  const subscriptionLink = ref('')
  const { getAccessTokenSilently = async () => '', isAuthenticated } = useAuth()
  const userStore = useUserStore()
  const toast = useToast()

  const manageSubscription = async (plan: string = 'default', action: 'upgrade' | 'manage' | 'subscribe' = 'manage') => {
    if (!isAuthenticated.value) {
      toast.error('You must be logged in to manage your subscription.')
      return
    }

    try {
      const token = await getAccessTokenSilently()
      const headers = {
        Authorization: `Bearer ${token}`,
      }

      const response = await axios.post(
        '/create-checkout-session',
        { plan, action },
        { headers },
      )
      const instance = getCurrentInstance()
      if (instance) {
        const { $trackLeadConversion } = instance.appContext.config.globalProperties
        $trackLeadConversion()
      }

      if (response.data.checkout_url) {
        subscriptionLink.value = response.data.checkout_url
      } else if (response.data.url) {
        subscriptionLink.value = response.data.url
      } else {
        throw new Error('No valid URL received from the server')
      }

      await userStore.checkSubscription(token)
    } catch (error: any) {
      console.error('Failed to manage subscription:', error)
    }
  }

  return { subscriptionLink, manageSubscription }
}
